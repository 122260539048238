<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
            :src="require('../assets/logo_icon_192.png')"
            class="my-3"
            contain
            height="100"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3 accent--text">
          Clinic
        </h1>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col
          class="mb-5"
          cols="4"
          offset="4"
      >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
          ></v-text-field>

          <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="showPassword ? 'text' : 'password'"
              name="input-10-1"
              label="Password"
              counter
              @click:append="showPassword = !showPassword"
          ></v-text-field>
          <p v-if="error" class="warning">{{ error }}</p>
          <p v-if="$store.state.loginMessage" class="warning">{{ $store.state.loginMessage }}</p>

          <v-btn color="primary" class="mr-4" @click="login()" > Login </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: 'LoginPage',

  data: () => ({
    valid: true,
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],

    password: "",
    showPassword: false,
    rules: {
      required: value => !!value || 'Required.'
    },

    error: "",
    data: {}
  }),

  methods: {

    async login() {
      this.error = "";

      let url = this.getAPIHost() + "auth/cookie/login";

      let formData = new FormData();
      formData.append('username', this.email);
      formData.append('password', this.password);
      // var self = this;
      axios.post(url, formData, {withCredentials: true})
          .then(res => { this.getUser(res); })
          .catch(err => {
                console.error('Axios Error :', err);
              }
          )

    },

    getUser(res) {
      console.log(res)
      let userUrl = this.getAPIHost() + "users/me";
      axios.get(userUrl, {withCredentials: true})
          .then(userRes => (this.setUser(userRes.data)))
          .catch(err => {
                console.error('Axios Error :', err)
              }
          );
    },

    setUser(userData) {
      this.$store.commit('login', userData);
    }
  }

}
</script>
