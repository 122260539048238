<template>
  <v-container>
    <user-list v-if="isAdmin"></user-list>
  </v-container>
</template>

<script>

import UserList from './UserList';

export default {
  name: 'HomePage',
  components: {
    'user-list': UserList
  },

  data: () => ({

  }),
  methods: {
  },

  computed: {
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    },
    isAdmin() {
      return this.$store.state.isAdmin;
    },
    isClinic() {
      return this.$store.state.isClinic;
    }
  },
  created() {
    this.$store.commit("unsetClinic")
    console.log(this.id);
  }
}
</script>
