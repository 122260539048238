<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
            :src="require('../assets/logo_icon_192.png')"
            class="my-3"
            contain
            height="100"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3 pink--text">
          Clinic
        </h1>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col
          class="mb-5"
          cols="4"
          offset="4"
      >
        <div v-if="result">
          <p>{{ result }}</p>
          <p><a href="/">Go to Login Page</a></p>
        </div>
        <v-form
            v-else
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <v-text-field
              v-model="password1"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="showPassword ? 'text' : 'password'"
              name="input-10-1"
              label="Password"
              counter
              @click:append="showPassword = !showPassword"
          ></v-text-field>

          <v-text-field
              v-model="password2"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="showPassword ? 'text' : 'password'"
              name="input-10-1"
              label="Repeat Password"
              counter
              @click:append="showPassword = !showPassword"
          ></v-text-field>
          <p v-if="error" class="warning">{{ error }}</p>
          <v-btn color="success" class="mr-4" @click="resetPassword()" > Reset </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: 'ResetPasswordPage',

  props:{
    id: String,
    token: String,
  },
  data: () => ({
    valid: true,
    password1: "",
    password2: "",
    showPassword: false,
    rules: {
      required: value => !!value || 'Required.'
    },
    error: "",
    result: "",
  }),
  methods: {
    resetPassword() {
      this.error = "";
      if (this.password1 !== this.password2) {
        this.error = "Passwords do not match."
      } else {

        let url = this.getAPIHost() + "auth/reset-password";

        let data = {
          token: this.token,
          password: this.password1,
        }

        axios.post(url, data, {withCredentials: true})
            .then(res => {
              if (res.status === 200) {
                this.password1 = "";
                this.password2 = "";
                this.result = "Password changed."
              }
            })
            .catch(err => {
                  this.error = "Something went wrong. Please contact the developer.";
                  console.error('Axios Error :', err);
                }
            )
      }
    }
  }
}
</script>
