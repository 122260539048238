<template>
  <div>
    <v-row class="mt-4" align="center">
      <v-col cols="9">
        <h1 class="display-2 font-weight-bold mb-3 primary--text">
          Users
        </h1>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2">
        <add-user @onAddUser="loadUsers()"></add-user>
      </v-col>
    </v-row>

    <simple-dialog :show-dialog.sync="linkDialog" title="Reset Password Link" :link="resetPasswordLink" />
    <edit-user-dialog :show-dialog.sync="showEditDialog" :user="userToEdit" @onUserUpdated="loadUsers()"/>

    <!--    <v-row>-->
    <!--      <v-col cols="12">-->
    <!--        <v-text-field-->
    <!--            v-model="search"-->
    <!--            label="Search"-->
    <!--            outlined-->
    <!--        ></v-text-field>-->
    <!--      </v-col>-->
    <!--    </v-row>-->

    <v-row class="text-center">
      <v-col class="mb-4">
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Email
              </th>
              <th class="text-left">
                Role
              </th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in users" :key="item.name" @click.left.stop.prevent="gotoClinic(item)">
              <td class="text-left"><a :href="'/clinic/' + item.id" style="text-decoration: none; color: black;" >{{ item.name }}</a></td>
              <td class="text-left">{{ item.email }}</td>
              <td class="text-left">{{ getRole(item) }}</td>
              <td class="text-right">
                <v-btn class="mr-4" small icon @click.stop="getResetPasswordLink(item.id)"><v-icon>mdi-lock</v-icon></v-btn>
                <v-btn small icon @click.stop="editUser(item)"><v-icon>mdi-pencil</v-icon></v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>


</template>

<script>
import AddUserDialog from './AddUserDialog';
import SimpleDialog from './SimpleDialog';
import EditUserDialog from './AdminEditUserDialog';
import axios from "axios";

export default {
  name: 'UserList',

  components: {
    'add-user': AddUserDialog,
    'simple-dialog': SimpleDialog,
    'edit-user-dialog': EditUserDialog,
  },

  data: () => ({
    search: "",
    users: [],
    linkDialog: false,
    resetPasswordLink: "",
    showEditDialog: false,
    userToEdit: {},
  }),

  methods: {
    gotoClinic(clinic) {
      this.$store.commit("setClinic", clinic);
      this.$router.push("/clinic/" + clinic.id);
    },
    openClinic(clinic) {
      let url = "/clinic/" + clinic.id;
      let middleClick = new MouseEvent( "click", { "button": 1, "which": 2 });
      // let clickEvent = new Event('click');
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: url,
      }).dispatchEvent(middleClick); // DOESNT WORK
      console.log(url)
      //dispatchEvent(middleClick);
    },
    getResetPasswordLink(id) {
      let url = this.getAPIHost() + "users/" + id + "/reset_password_link"
      axios.get(url, {withCredentials: true})
          .then(response => {
            this.resetPasswordLink = this.getHost() + response.data;
            this.linkDialog = true;
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              this.$store.dispatch('unauthorized');
            }
            console.error('Axios Error :', err);
          });
    },
    editUser(userItem) {
      this.userToEdit = userItem;
      this.showEditDialog = true;
    },
    async loadUsers() {
      let url = this.getAPIHost() + "users"
      axios.get(url, {withCredentials: true})
          .then(response => (this.users = response.data))
          .catch(err => {
            if (err.response && err.response.status === 401) {
              this.$store.dispatch('unauthorized');
            }
            console.error('Axios Error :', err);
          });
    },
    getRole(item) {
      if (item.is_superuser) return "Admin";
      return "Clinic"
    }
  },

  created() {
    this.loadUsers();
  }
}
</script>
