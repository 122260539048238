<template>
  <div class="mb-4">
    <v-card>
      <v-card-title :class="classObject">
        {{ exercise.name }} ({{ calcDuration }})
<!--        <v-col align="right">-->
<!--          <v-btn @click="editMode=!editMode" small icon ><v-icon>mdi-pencil</v-icon></v-btn>-->
<!--        </v-col>-->
      </v-card-title>
      <v-card-text>
        <summary-chart :exercise="exercise" :plotAllSets="plotAllSets" :sensitivity="sensitivity"></summary-chart>
      </v-card-text>

      <v-card-text>
        <v-row class="align-baseline" v-if="isEditable('sets_row')">
          <v-col cols="2">
            <h3>Sets:</h3>
          </v-col>
          <v-col>
            <v-text-field
                v-model.number="exercise.sets"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                min="0"
                required
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2">
            Rest In Between:
          </v-col>
          <v-col :v-if="isEditable('rest.sets')">
            <v-text-field
                v-model.number="exercise.rest_sets"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                required
                append="s"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="align-baseline" v-if="isEditable('reps_row')">
          <v-col cols="2">
            <h3>Reps:</h3>
          </v-col>
          <v-col>
            <v-text-field
                v-model.number="exercise.reps"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                min="1"
                required
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2">
            Rest In Between:
          </v-col>
          <v-col>
            <v-text-field
                v-model.number="exercise.rest_reps"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                required
                append="s"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="align-baseline" v-if="isEditable('duration_row')">
          <v-col cols="2">
            <h3>Duration:</h3>
          </v-col>
          <v-col>
            <v-text-field
                v-model.number="duration"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                min="1"
                required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>

  </div>
</template>

<script>

import ExerciseSummaryChart from "./ExerciseSummaryChart";

export default {
  props: {
    'exercise': {},
    'plotAllSets': Boolean,
    'sensitivity': Number
  },

  components: {
    "summary-chart": ExerciseSummaryChart
  },

  data: () => ({
    duration: 1.0
  }),

  methods: {
    isEditable(key){
      if (this.exercise.id === "pfmc_endurance") {
        if (key === "reps_row") return false;
      }
      return true;
    },

    editIconColor(value) {
      if (value) {
        return "blue"
      }
      return ""
    }
  },
  computed: {
    disabled() {
      return this.exercise.sets === 0;
    },
    classObject: function () {
      return {
        'grey--text': this.disabled
      }
    },
    calcDuration() {
      let sets = this.exercise.sets;
      let reps = this.exercise.reps;

      if (this.disabled) return "Disabled"

      var duration = 0.0;
      this.exercise.actions.map(action => {
        if (action.duration !== undefined) duration += action.duration;
      });

      let rest_sets = this.exercise.rest_sets
      let rest_reps = this.exercise.rest_reps

      let duration_set = reps * duration + (reps - 1) * rest_reps;
      let total_duration = sets * duration_set + (sets - 1) * rest_sets;

      if (total_duration < 60) return Math.round(total_duration) + " secs";

      let minutes = Math.floor(total_duration / 60);
      let seconds = Math.round(60 * ((total_duration / 60) - minutes));
      return minutes + " min, " + Math.round(seconds) + " secs";

    }
  },
  created() {
    this.duration = this.exercise.actions[0].duration;
  },
  watch: {
    duration() {
      this.exercise.actions.map(action => {
        if (action.duration !== undefined) {
          action.duration = this.duration;
        }
      })
    }
  }
}
</script>