<template>
  <v-container>
    <v-row class="mt-4" align="center">
      <v-col cols="8">
        <h1 class="display-2 font-weight-bold mb-3 primary--text" >
             <v-btn @click="$router.go(-1)" x-large icon  color="primary"><v-icon>mdi-arrow-left</v-icon></v-btn> {{ title }}
        </h1>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="3">
        <v-btn color="primary" dark x-large @click="gotoProtocol()">
          <v-icon left>mdi-pencil</v-icon> Edit Programme
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="mt-4 mb-4"></v-divider>
    <v-row class="mt-4">

<!--      <v-col cols="6">-->
<!--        <v-row>-->
<!--          <v-col cols="12" align="center">-->
<!--            <h2 class="grey&#45;&#45;text text&#45;&#45;darken-1">Summary</h2>-->
<!--            <v-divider></v-divider>-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--        <v-row class="mt-4 mx-1">-->
<!--          <v-col cols="8">-->
<!--            <p><strong>Last Clinic Upload:</strong></p>-->
<!--          </v-col>-->
<!--          <v-col cols="4" align="right">-->
<!--            Yesterday at 2pm <v-btn small icon color="primary" ><v-icon>mdi-arrow-right</v-icon></v-btn>-->
<!--          </v-col>-->
<!--        </v-row>-->

<!--        <v-row class="mt-4 mx-1">-->
<!--          <v-col cols="8">-->
<!--            <p><strong>Last Home Upload:</strong></p>-->
<!--          </v-col>-->
<!--          <v-col cols="4" align="right">-->
<!--            3 Hours ago <v-btn small icon color="primary" ><v-icon>mdi-arrow-right</v-icon></v-btn>-->
<!--          </v-col>-->
<!--        </v-row>-->

<!--        <v-row class="mt-4 mx-1">-->
<!--          <v-col cols="8">-->
<!--            <p><strong>Diary:</strong></p>-->
<!--          </v-col>-->
<!--          <v-col cols="4" align="right">-->
<!--            (Leaks: 2) <v-btn small icon color="primary" ><v-icon>mdi-arrow-right</v-icon></v-btn>-->
<!--          </v-col>-->
<!--        </v-row>-->

<!--        <v-row class="mt-4 mx-1">-->
<!--          <v-col cols="8">-->
<!--            <p><strong>Symptom Resolution:</strong></p>-->
<!--          </v-col>-->
<!--          <v-col cols="4" align="right">-->
<!--            <v-btn small icon color="primary" ><v-icon>mdi-arrow-right</v-icon></v-btn>-->
<!--          </v-col>-->
<!--        </v-row>-->

<!--        <v-row class="mt-4 mx-1">-->
<!--          <v-col cols="8">-->
<!--            <p><strong>Adherence:</strong></p>-->
<!--          </v-col>-->
<!--          <v-col cols="4" align="right">-->
<!--            <v-btn small icon color="primary" ><v-icon>mdi-arrow-right</v-icon></v-btn>-->
<!--          </v-col>-->
<!--        </v-row>-->

<!--      </v-col>-->

      <v-col>
        <v-row>
          <v-col cols="12" align="center">
            <h2 class="grey--text text--darken-1">Data</h2>
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="mx-2">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-right">
                    Date
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in patient.sessions" :key="item.id" @click.left.stop.prevent="gotoData(item)">
                  <td><a :href="'/data/' + item.id" style="text-decoration: none; color: black;" >{{ item.name }}</a></td>
                  <td class="text-right">{{ item.uploaded_at }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

        </v-row>


      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: 'PatientDash',
  props: ['id'],
  data: () => ({
    title: "",
    patient: {},
  }),
  methods: {
    async downloadPatient() {
      let url = this.getAPIHost() + "subjects/" + this.id
      axios.get(url, {withCredentials: true})
          .then(response => {
            this.patient = response.data;
            console.log(this.patient);
            this.loadPatient();
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              this.$store.dispatch('unauthorized');
            }
            console.error('Axios Error :', err);
          });
    },
    gotoProtocol() {
      this.patient.protocols.map(protocol => {
        if (protocol.name === "default") {
          this.$router.push("/protocol/" + protocol.id);
        }
      })
    },
    gotoData(data) {
      this.$router.push({path: "/data/" + data.id});
    },
    openData(data) {
      window.open("/data/" + data.id, '_blank');
    },
    loadPatient() {
      this.title = this.patient.name;
    },
    lastSeen(patient) {
      if (patient.last_seen == null) return "Never"
      return patient.last_seen;
    },
    getBreadcrumb() {
      return [
        {label: this.$store.state.clinicName, id: this.$store.state.clinic.id}
      ]
    }
  },
  created() {
    this.downloadPatient()
  }

}
</script>
