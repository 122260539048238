import HomePage from "./components/HomePage"
import AdminPage from "./components/AdminPage"
import ClinicPage from "./components/ClinicPage"
import PatientPage from "./components/PatientPage"
import DataPage from "./components/DataPage"
import ProtocolPage from "./components/ProtocolPage"
import UserPage from "./components/UserPage"
import ResetPasswordPage from "./components/ResetPasswordPage"

export default [
    {path: '/', component: HomePage},
    {path: '/admin/:id', component: AdminPage, props: true},
    {path: '/clinic/:id', component: ClinicPage, props: true},
    {path: '/patients/:id', component: PatientPage, props: true},
    {path: '/data/:id', component: DataPage, props: true},
    {path: '/protocol/:id', component: ProtocolPage, props: true},
    {path: '/user/:id', component: UserPage, props: true},
    {path: '/reset/:id/:token', component: ResetPasswordPage, props: true},
]