<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Edit User {{ $route.params.id }}
        </h1>

        <p class="subheading font-weight-regular">
          For help and collaboration please contact us.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'User Page',

    data: () => ({
      userId: this.$route.params.id
      // email: '',
      // emailRules: [
      //   v => !!v || 'E-mail is required',
      //   v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      // ],
      // showPassword: false,
      // rules: {
      //   required: value => !!value || 'Required.'
      // },

    }),
  }
</script>
