<template>
  <v-app>
    <v-app-bar
        app
        :color="appBarColor"
        dark
    >
      <div class="d-flex align-center">
        <v-img
            alt="Vuetify Name"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            min-width="100"
            :src="require('./assets/femfit_logo_white.png')"
            width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <div v-if="clinicName" class="d-flex align-center" @click="gotoClinic()">
        <v-icon class="mr-3" large>mdi-home</v-icon>
        <h1>{{ clinicName }}</h1>
      </div>

      <v-spacer></v-spacer>

      <v-menu v-if="isAuthenticated" offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              text
              v-bind="attrs"
              v-on="on"
              @click="goHome()"
          >
            <!--            <v-icon v-if="isAdmin" class="mx-2">mdi-home</v-icon>-->
            <span class="mr-2">{{ userName }}</span>
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group
              v-model="selectedItem"
              color="primary"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><a :href="latestAppUrl">Download App</a></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
<!--            <v-list-item>-->
<!--              <v-list-item-content>-->
<!--                <v-list-item-title @click="openEditUserDialog(userId)">Edit Profile</v-list-item-title>-->
<!--              </v-list-item-content>-->
<!--            </v-list-item>-->
            <v-list-item>
              <v-list-item-title @click="logout()">Logout</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

    </v-app-bar>

    <v-main style="margin: auto; width: 960px">

      <LoginPage v-if="requiresAuthentication()" ></LoginPage>

      <router-view v-else></router-view>

    </v-main>
  </v-app>
</template>

<script>

import LoginPage from "./components/LoginView";
// import EditProfile from "./components/EditProfileDialog";
import axios from "axios";

export default {
  name: 'App',

  components: {
    LoginPage,
    // 'edit-profile': EditProfile
  },

  data: () => ({
    latestApp: "",
    latestAppUrl: "",
    selectedItem: -1,
  }),
  methods: {
    async checkLogin() {
      let url = this.getAPIHost() + "users/me"
      axios.get(url, {withCredentials: true})
          .then(response => {
            let userData = response.data;
            this.$store.commit('login', userData);
          })
          .catch(err => {err})
    },
    requiresAuthentication() {
      if (this.$router.currentRoute.path.startsWith("/reset/")) {
        return false;
      }
      return !this.isAuthenticated
    },
    goHome() {
      let path = this.$store.state.isAdmin ?
          '/admin/' + this.$store.state.user.id :
          '/clinic/' + this.$store.state.clinic.id;
      if (this.$router.currentRoute.path !== path) {
        this.$router.push(path);
      }
    },
    gotoClinic() {
      let path = '/clinic/' + this.$store.state.clinic.id;
      if (this.$router.currentRoute.path !== path) {
        this.$router.push(path);
      }
    },
    logout() {
      let formData = new FormData();
      let url = this.getAPIHost() + "auth/cookie/logout";
      axios.post(url, formData, {withCredentials: true})
          .then(res => { console.log(res); })
          .catch(err => console.error('Axios Error :', err))

      this.$store.dispatch("logout");
    },
    onEditProfile(action) {
      console.log("Menu action " + action);
      this.showEditProfileDialog = true;
      this.selectedItem = -1;
    },
    openEditUserDialog(userId) {
      this.$store.dispatch("showEditUser", userId);
    },
    async downloadAppMetadata() {
      let url = this.getAPIHost() + "app/latest"
      axios.get(url, {withCredentials: true})
          .then(response => {
            this.latestApp = response.data.production.path;
            this.latestAppUrl = this.getAPIHost() + "app/" + this.latestApp;
          })
          .catch(err => {err})
    },
    // downloadAppUrl() {
    //   return this.getAPIHost() + "app/" + this.latestApp;
    // },
    async downloadApp() {
      axios({
        url: this.getAPIHost() + "apps/" + this.latestApp,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', this.latestApp);
        document.body.appendChild(fileLink);
        fileLink.click();
      });

    },
  },
  computed: {
    appBarColor() {
      if (this.$store.state.clinic.id && this.$store.state.clinic.id !== this.$store.state.user.id) {
        return "red";
      }
      return "primary";
    },

    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    },
    isAdmin() {
      return this.$store.state.isAdmin;
    },
    userId() {
      return this.$store.state.user.id;
    },
    userName() {
      return this.$store.state.userName;
    },
    clinicName() {
      return this.$store.state.clinicName;
    },
    editUserId() {
      return this.$store.state.editUser;
    },

  },
  created() {
    this.checkLogin();
    this.downloadAppMetadata();
  }
};
</script>
