<template>
  <div>
    <v-row class="mt-4" align="center">
      <v-col cols="9">
        <h1 class="display-2 font-weight-bold mb-3 primary--text">
          Patients
        </h1>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2">
        <add-patient @onAddPatient="addPatient"></add-patient>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-text-field
            v-model="search"
            label="Search"
            outlined
        ></v-text-field>
      </v-col>
    </v-row>

    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Name/ID
          </th>
          <th class="text-left">
            Last Seen
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in patients" :key="item.id" @click.left.stop.prevent="gotoPatient(item)">
          <td><a :href="'/patients/' + item.id" style="text-decoration: none; color: black;" >{{ item.name }}</a></td>
          <td>{{ lastSeen(item) }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>


</template>

<script>
import AddPatientDialog from './AddPatientDialog';
import axios from "axios";

export default {
  name: 'PatientList',
  props: ["clinicId"],
  components: {
    'add-patient': AddPatientDialog
  },

  data: () => ({
    search: "",
    patients: []

  }),
  methods: {
    gotoPatient(patient) {
      this.$router.push("/patients/" + patient.id);
    },
    addPatient(patient) {
      this.patients.push(patient);
      this.downloadPatientsList();
    },
    async downloadPatientsList() {
      console.log("Request Patients")
      let url = this.getAPIHost() + "users/" + this.clinicId + "/subjects"
      axios.get(url, {withCredentials: true})
          .then(response => {
            this.patients = response.data
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              this.$store.dispatch('unauthorized');
            }
            console.error('Axios Error :', err);
          });
    },
    lastSeen(patient) {
      if (patient.last_seen == null) return "Never"
      return patient.last_seen;
    }
  },
  computed: {
    userId() {
      return this.$store.state.user.id;
    }
  },
  created() {
    this.downloadPatientsList();
  }
}
</script>
