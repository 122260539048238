<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            dark
            x-large
            v-bind="attrs"
            v-on="on"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          Add User
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">New User</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Name*"
                    required
                    v-model = name
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Email*"
                    required
                    v-model = email
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                    label="Is Admin"
                    v-model = isAdmin
                />
              </v-col>
            </v-row>
          </v-container>
          <small>* required field</small><br/>
          <p v-if="error" class="warning">{{ error }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="addUser()"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    dialog: false,
    name: "",
    email: "",
    isAdmin: false,
    error: ""
  }),
  methods: {
    async addUser() {
      this.error = "";

      let url = this.getAPIHost() + "users";

      let data = {
        name: this.name.trim(),
        email: this.email.trim(),
        is_admin: this.isAdmin,
      }

      axios.post(url, data, {withCredentials: true})
          .then(res => {
            if (res.status === 200) {
              this.$emit("onAddUser");
              this.dialog = false;
            }
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              this.$store.dispatch('unauthorized');
            }
            console.error('Axios Error :', err);
          });

    },
  }
}
</script>