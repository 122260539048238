<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Edit User</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Name*"
                    required
                    v-model = name
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Email*"
                    required
                    v-model = email
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                    label="Is Admin"
                    v-model = isSuperuser
                />
              </v-col>
              <v-col cols="12">
                <v-checkbox
                    label="Is Active"
                    v-model = isActive
                />
              </v-col>
            </v-row>
          </v-container>
          <small>* required field</small><br/>
          <p v-if="error" class="warning">{{ error }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="updateUser()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  props: {
    'showDialog': Boolean,
    'user': Object,
  },
  data() { return {
    userId: "",
    name: "",
    email: "",
    isSuperuser: false,
    isActive: true,
    error: ""
  }},
  methods: {
    closeDialog() {
      this.$emit("update:showDialog", false);
    },
    async updateUser() {
      this.error = "";

      if (this.userId !== this.user.id) {
        this.error = "Failed: Ids don't match. Something serious went wrong."
        return;
      }

      let url = this.getAPIHost() + "users/" + this.user.id;

      var hasChanged = false;
      let data = {};
      if (this.name !== this.user.name) {
        data.name = this.name;
        hasChanged = true;
      }
      if (this.email !== this.user.email) {
        data.email = this.email;
        hasChanged = true;
      }
      if (this.isSuperuser !== this.user.is_superuser) {
        data.is_superuser = this.isSuperuser;
        hasChanged = true;
      }
      if (this.isActive !== this.user.is_active) {
        data.is_active = this.isActive;
        hasChanged = true;
      }

      console.log(hasChanged);
      console.log(data);
      if (hasChanged) {
        axios.patch(url, data, {withCredentials: true})
            .then(res => {
              console.log(res);
              if (res.status === 200) {
                this.$emit("onUserUpdated");
                this.$emit("update:showDialog", false);
              }
            })
            .catch(err => {
              if (err.response && err.response.status === 401) {
                this.$store.dispatch('unauthorized');
              }
              console.error('Axios Error :', err);
            });
      }
      else {
        this.$emit("update:showDialog", false);
      }
    },
  },
  watch: {
    'showDialog': {
      deep: true,
      handler(newValue) {
        this.userId = this.user.id;
        this.name = this.user.name;
        this.email = this.user.email;
        this.isSuperuser = this.user.is_superuser;
        this.isActive = this.user.is_active;
      }
    },
  },
}
</script>