<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          {{ text }}
          <a v-show="link" :href="link">{{link}}</a>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  props: {
    'showDialog': Boolean,
    'title': String,
    'text': String,
    'link': String,
  },
  data: () => ({
  }),
  methods: {
    closeDialog() {
      this.$emit("update:showDialog", false);
    }
  }
}
</script>