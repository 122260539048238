<template>
  <div>
    <h4>Time: {{ getFormattedTime(time) }}</h4>
    <canvas :id="chartId" width="500" height="200"></canvas>
    <canvas :id="barChartId" width="500" height="200"></canvas>
  </div>
</template>
<script>

import axios from "axios";
import {
  Chart,
  LineController, LineElement, PointElement,
  BarController, BarElement,
  ScatterController,
  CategoryScale, LinearScale, Title } from "chart.js"

export default {
  name: 'PressureGraph',
  props:{
    sessionId: String,
    graphId: String,
  },
  data: () => ({
    chart: null,
    barChart: null,
    time: 0,
    times: [],
    pressures: [],
    baselines: [],
    refPressures: [0, 0, 0, 0, 0, 0, 0, 0],
    data: {
      labels: [],
      datasets: []},
    barData: {
      labels: [],
      datasets: []
    },
    colors: [
      [150, 0, 0],
      [247, 113, 137],
      [206, 144, 50],
      [151, 164, 49],
      [50, 177, 102],
      [54, 173, 164],
      [57, 167, 208],
      [164, 140, 244],
      [245, 97, 221],
    ],
  }),
  methods: {
    initData() {
      this.data = {
        labels: [],
        datasets: [
          this.initDataLine(1),
          this.initDataLine(2),
          this.initDataLine(3),
          this.initDataLine(4),
          this.initDataLine(5),
          this.initDataLine(6),
          this.initDataLine(7),
          this.initDataLine(8),
        ]}
      this.barData = {
        labels: [1, 2, 3, 4, 5, 6, 7, 8],
        datasets: [{
          label: 'My First Dataset',
          data: [1, 1, 1, 1, 1, 1, 1, 1],
          backgroundColor: [
            this.getColor(1, 0.2),
            this.getColor(2, 0.2),
            this.getColor(3, 0.2),
            this.getColor(4, 0.2),
            this.getColor(5, 0.2),
            this.getColor(6, 0.2),
            this.getColor(7, 0.2),
            this.getColor(8, 0.2),
          ],
          borderColor: [
            this.getColor(1, 1),
            this.getColor(2, 1),
            this.getColor(3, 1),
            this.getColor(4, 1),
            this.getColor(5, 1),
            this.getColor(6, 1),
            this.getColor(7, 1),
            this.getColor(8, 1),
          ],
          borderWidth: 1
        }]
      }
    },
    initDataLine(i) {
      return {
        label: 'Ch' + i,
        backgroundColor: this.getColor(i, 1),
        borderColor: this.getColor(i, 1),
        tension: 0.1,
        pointHoverRadius: 1,
        data: [],
      }
    },
    getColor(index, alpha) {
      let c = this.colors[index];
      if (alpha < 1.0) {
        return 'rgba(' + c[0] + ', ' + c[1] + ', ' + c[2] + ', ' + alpha + ')';
      }
      return 'rgb(' + c[0] + ', ' + c[1] + ', ' + c[2] + ')';
    },
    async downloadData() {
      let url1 = this.getAPIHost() + "sessions/" + this.sessionId + "/generated/pressures_" + this.graphId + ".json"
      axios.get(url1, {withCredentials: true})
          .then(response => {
            this.times = response.data.times;
            this.pressures = response.data.pressures;
            this.updateData();
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              this.$store.dispatch('unauthorized');
            }
            console.error('Axios Error :', err);
          });
    },
    updateData() {
      this.zeroPressures(0);
      this.data.labels = this.times;
      for (var i = 0; i < 8; i++) {
        this.data.datasets[i].data = this.pressures[i];
      }
      this.chart.update();

    },
    updatePressureProfile(index) {
      this.time = this.times[index];
      let labels = [];
      let pressureProfile = [];
      for (var i = 0; i < 8; i++) {
        labels.push([this.pressures[i][index].toFixed(1), (this.pressures[i][index] - this.refPressures[i]).toFixed(1)]);
        pressureProfile.push(this.pressures[i][index] - this.refPressures[i]);
      }
      this.barData.labels = labels;
      this.barData.datasets[0].data = pressureProfile;
      this.barChart.update();
    },
    zeroPressures(index) {
      let pressureProfile = [];
      var minValue = 500.0;
      var maxValue = -300.0;
      for (var i = 0; i < 8; i++) {
        let refValue = this.pressures[i][index]
        pressureProfile.push(refValue);

        for (var j = 0; j < this.pressures[0].length; j++) {
          let delta = this.pressures[i][j] - refValue;
          if (delta < minValue) minValue = delta;
          if (delta > maxValue) maxValue = delta;
        }

      }

      this.barChart.options.scales.yAxis.min = minValue;
      this.barChart.options.scales.yAxis.max = maxValue;

      this.refPressures = pressureProfile;
    },
    chartConstructor() {
      Chart.register(LineController, LineElement, PointElement,
          ScatterController, BarController, BarElement,
          CategoryScale, LinearScale, Title);

      const lineConfig = {
        type: 'line',
        responsive: true,
        data: this.data,
        options: {
          animation: false,
          showLine: true,
          borderWidth: 1,
          elements: {
            point: {
              radius: 0
            }
          },
          events: ['mousemove', 'click'],
          onClick: (e) => {
            const elem = this.chart.getElementsAtEventForMode(e, 'nearest', {axis: 'x', intersect: false});
            if (elem.length > 0) {
              this.zeroPressures(elem[0].index);
            }
          },
          onHover: (e) => {
            const elem = this.chart.getElementsAtEventForMode(e, 'nearest', {axis: 'x', intersect: false});
            if (elem.length > 0) {
              this.updatePressureProfile(elem[0].index);
            }
          },
          interaction: {
            mode: 'nearest',
            intersect: true,
          },
          scales: {
            x: {
              ticks: {
                callback: (value, index, values) => {
                  return this.getFormattedTime(this.times[index]);
                }
              }
            }
          }
        }
      };

      const barConfig = {
        type: 'bar',
        responsive: true,
        data: this.barData,
        options: {
          animation: false,
          scales: {
            yAxis: {
              min: -50,
              max: 160,
            }
          }
        }
      };

      this.chart = new Chart(document.getElementById(this.chartId), lineConfig);
      this.barChart = new Chart(document.getElementById(this.barChartId), barConfig);

    },
    getFormattedTime(time) {
      let seconds = time / 1000.0;
      return seconds.toFixed(1) + 's';
    }
  },
  computed: {
    chartId() {
      return "pressure-chart-" + this.graphId;
    },
    barChartId() {
      return "pressure-bars-" + this.graphId;
    }
  },
  watch: {
    plotAllSets: function() {
      this.updateData();
      this.chart.update();
    }
  },
  created() {
    // this.downloadData();
  },
  mounted(){
    this.initData();
    this.chartConstructor();
    this.downloadData();
  }
}
</script>
