<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            dark
            x-large
            v-bind="attrs"
            v-on="on"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          Add Patient
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">New Patient</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Name/Id"
                    required
                    autofocus
                    v-on:keyup.enter="addPatient()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>* required field</small>
          <p v-if="error" class="warning">{{ error }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="addPatient()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    dialog: false,

    name: '',
    nameRules: [
      v => !!v || 'A name is required',
    ],

    error: "",

  }),

  methods: {
    async addPatient() {
      if (!this.name.trim()) {
        this.error = "Name required";
        return;
      }

      let url = this.getAPIHost() + "users/me/subjects";

      let data = { name: this.name.trim() }

      axios.post(url, data, {withCredentials: true})
          .then(response => {
            if (response.status === 200) {
              let patient = response.data;
              console.log(patient);
              this.$emit("onAddPatient", patient);
              this.dialog = false;
            } else {
              this.error = "Failed to add patient";
            }
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              this.$store.dispatch('unauthorized');
            }
            console.error('Axios Error :', err);
          });



      // const requestOptions = {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ name: this.name.trim() })
      // };
      // const response = await fetch(this.getAPIHost() + "users/" + this.clinicId + "/patients", requestOptions);
      // if (await response.status === 200) {
      //   let patient = await response.json();
      //   this.$emit("onAddPatient", patient);
      //   this.dialog = false;
      // } else {
      //   this.error = "Failed to add patient";
      // }
    }
  },

  computed: {
    clinicId() {
      return this.$store.state.clinic.id;
    }
  }
}
</script>