import Vue from 'vue'
import Vuex from 'vuex'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './plugins/router'
import axios from "axios";

Vue.use(Vuex)

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    getHost() {
      if (window.location.origin.includes("localhost")) {
        return "http://localhost:8080/";
      }
      return 'https://femfit.clinic/';
    },
    getAPIHost() {
      if (window.location.origin.includes("localhost")) {
        return "http://localhost:8000/";
      }
      return 'https://api.femfit.clinic/';
    }
  }
})


const store = new Vuex.Store({
  state: {
    isAuthenticated: false,
    isAdmin: false,
    isClinic: false,

    userName: "",
    user: {},

    clinicName: "",
    clinic: {},

    editUser: 0,

    patientId: "",
    patientName: "",

    sessionId: "",
    sessionName: "",

    loginMessage: ""

  },
  mutations: {
    setEditUser(state, userId) {
      state.editUser = userId;
    },

    setClinic(state, clinic) {
      state.clinic = clinic;
      state.clinicName = clinic.name;
    },

    unsetClinic(state) {
      state.clinic = {};
      state.clinicName = "";
    },

    login(state, user) {
      console.log("Login" + user.name);
      state.loginMessage = ""

      state.isAuthenticated = true;
      state.isAdmin = user.is_superuser;
      state.isClinic = !state.isAdmin;
      state.userName = "Menu";
      state.user = user;

      if (state.isAdmin) {
        if (router.currentRoute.path === '/') {
          router.push('/admin/' + user.id);
        }
        state.userName = user.name;
        state.clinicName = "";
      }

      else {
        if (router.currentRoute.path === '/') {
          router.push('/clinic/' + user.id);
        }
        state.clinicName = user.name;
        state.clinic = user;
      }
    },
    logout(state) {
      console.log("Logout");
      this.dispatch("resetUser");
    },
    unauthorized(state) {
      if (this.state.isAuthenticated) {
        state.loginMessage = "Your login session may have expired. Please login again.";
      }
      this.dispatch("resetUser");
    },
    resetUser(state) {
      this.state.isAuthenticated = false;
      state.isAdmin = false;
      state.isClinic = false;

      state.userName = "";
      state.user = {};

      state.clinicName = "";
      state.clinic = {};

      state.selectedItem = -1;

      if (router.currentRoute.path !== '/') {
        router.push('/');
      }
    },
    updateUser(state, user) {
      console.log("Update User");

      if (user.id === state.user.id) {
        state.user = user;
        if (state.isAdmin) {
          state.userName = user.name;
        }
      }

      if (user.id === state.clinic.id) {
        state.clinic = user;
        state.clinicName = user.name;
      }
    },
  },
  actions: {
    resetUser(context) {
      context.commit("resetUser");
    },
    unauthorized(context) {
      console.log("Unauthorised");
      context.commit("unauthorized");
    },
    logout(context) {
      console.log("Logout");
      context.commit("logout");
    },
    onUpdateUser(context, user) {
      context.commit("updateUser", user);
    },
    showEditUser(context, userId) {
      context.commit("setEditUser", userId);
    },
    hideEditUser(context) {
      context.commit("setEditUser", 0);
    },
  }

})


new Vue({
  vuetify,
  render: h => h(App),
  router: router,
  store: store
}).$mount('#app')
