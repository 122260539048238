<template>
  <div>
    <canvas :id="chartId" width="500" height="120"></canvas>
  </div>
</template>
<script>

// import {Chart} from "chart.js";
import {
  Chart,
  LineController, LineElement, PointElement,
  ScatterController,
  CategoryScale, LinearScale, Title } from "chart.js"
// import { Chart } from "chart.js"

export default {
  name: 'ExerciseSummary',
  props:{
    exercise: Object,
    plotAllSets: Boolean,
    sensitivity: Number,
  },
  data: () => ({
    chart: null,
    data: {
      labels: [],
      datasets: [{
        label: 'Exercise Profile',
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgb(255, 99, 132)',
        data: [],
        // stepped: "before"
      }]}
  }),
  methods: {
    generateData() {
      let sensitivity = this.sensitivity;
      let sets = this.plotAllSets ? this.exercise.sets : 1;
      let reps = this.exercise.reps;

      let actions = this.exercise.actions;

      let rest_sets = parseFloat(this.exercise.rest_sets);
      let rest_reps = parseFloat(this.exercise.rest_reps);

      let labels = ["", "0"];
      let data = [{x: -1, y: 0}, {x: 0, y: 0}];

      var time = 0.0;
      for (let j = 0; j < sets; j++) {
        for (let i = 0; i < reps; i++) {
          for (let idx in actions) {
            let action = actions[idx];
            if (action.duration !== undefined) {
              let level = sensitivity * action.strength;
              labels.push("");
              data.push({x: time, y: level});
              time += action.duration;
              labels.push("");
              data.push({x: time, y: level});
            }
          }
          labels.push("");
          data.push({x: time, y: 0});
          if (i < reps - 1) {
            time += rest_reps;
            labels.push("");
            data.push({x: time, y: 0});
          }
        }
        if (j < sets - 1) {
          time += rest_sets;
          labels.push("");
          data.push({x: time, y: 0});
        }
      }

      this.data.labels = labels;
      this.data.datasets[0].data = data;

    },
    chartConstructor() {
      Chart.register(LineController, LineElement, PointElement,
          ScatterController,
          CategoryScale, LinearScale, Title);

      const config = {
        type: 'scatter',
        responsive: true,
        data: this.data,
        options: {
          animation: false,
          showLine: true,
          elements: {
            point:{
              radius: 0
            }
          }
        }
      };

      const chartElement = document.getElementById(this.chartId);
      this.chart = new Chart(chartElement, config);

    },
  },
  computed: {
    chartId() {
      return "rep-chart-" + this.exercise.id;
    }
  },
  watch: {
    'exercise': {
      deep: true,
      handler(newValue) {
        if (newValue != null) {
          this.generateData();
          this.chart.update();
        }
      }
    },
    sensitivity: function() {
      this.generateData();
      this.chart.update();
    },
    plotAllSets: function() {
      this.generateData();
      this.chart.update();
    }
  },
  mounted(){
    this.generateData();
    this.chartConstructor();
  }
}
</script>
