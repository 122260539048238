<template>
  <v-container>
    <patient-list :clinicId="id"></patient-list>
  </v-container>
</template>

<script>

import PatientList from './PatientList';

export default {
  name: 'HomePage',
  props: ["id"],
  components: {
    'patient-list': PatientList,
  },

  data: () => ({

  }),

  computed: {
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    },
    isAdmin() {
      return this.$store.state.isAdmin;
    },
    isClinic() {
      return this.$store.state.isClinic;
    }
  },
  created() {
    console.log(this.id);
  }
}
</script>
