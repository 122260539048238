<template>
  <v-container>
    <v-row class="mt-4" align="center">
      <v-col cols="8">
        <h1 class="display-2 font-weight-bold mb-3 primary--text">
          <v-btn @click="$router.go(-1)" x-large icon  color="primary"><v-icon>mdi-arrow-left</v-icon></v-btn>
          {{ title }}
        </h1>
      </v-col>
    </v-row>

    <v-divider class="mt-4 mb-4"></v-divider>

    <v-row class="mt-2" align="center">
      <v-col>
        <p><strong>Downloads</strong> :
          <a :href="getRawUrl()" target="_blank" rel="noreferrer noopener">[Raw]</a>
          <a :href="getCsvUrl()" target="_blank" rel="noreferrer noopener">[CSV]</a>
          <a :href="getUrl('generated/session.json')" target="_blank" rel="noreferrer noopener">[Session]</a></p>
        <div v-for="(item, index) in details" :key="index">
          <p v-if="item.type=='info'" class="alert alert-info"><strong>{{ item.title }}</strong> : {{ item.text }}</p>
          <p v-else-if="item.type=='warning'" class="alert alert-danger"><strong>{{ item.title }}</strong> : {{ item.text }}</p>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-4" align="center">
      <v-col>
        <v-switch
            v-model="showAllEvents"
            label="Show all actions"
        ></v-switch>
      </v-col>
    </v-row>
    <v-divider class="mt-4 mb-4"></v-divider>

    <v-row class="mt-4">
      <v-col>
        <v-timeline
            align-top
            dense
        >
          <v-timeline-item
              v-for="(item, index) in getFilteredTimeline()"
              :key="index"
              small
          >
            <div v-if="item.type === 'user_action'">
              <h4 :class="`font-weight-light mb-4 grey--text`">{{ getPrettyTime(item.time) }} : <span :class="`font-weight-thin mb-4 grey--text`">{{ getLabel(item) }}</span></h4>
            </div>
            <div v-else-if="item.type === 'note'">
              <h4 :class="`font-weight-light mb-4 grey--text`">{{ getPrettyTime(item.time) }} <span :class="`mb-4 red--text`">Note : {{ getLabel(item) }}</span></h4>

            </div>
            <div v-else>
              <h4 :class="`font-weight-light mb-4 grey--text`">{{ getPrettyTime(item.time) }} : <span :class="`headline font-weight-bold mb-4 ${getColor(item)}--text`">{{ getLabel(item) }}</span></h4>
            </div>

            <v-card v-if="hasGraph(item)">
              <v-card-text>
                <pressure-graph :sessionId="id" :graphId="item.graph_id" />
              </v-card-text>
            </v-card>

          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import axios from "axios";
import PressureGraph from "./PressureGraph";

export default {
  props: ["id"],
  name: 'DataView',

  components: {
    'pressure-graph': PressureGraph
  },
  data: () => ({
    showAllEvents: false,
    title: "Data View",
    bseUrl: "",
    session: {},
    summary: {},
    details: [],
    timeline: [],

  }),
  methods: {
    goBack() {
      this.$router.push("/patient/" + this.id);
    },
    getUrl(path) {
      return this.baseUrl + "/" + path;
    },
    getCsvUrl() {
      return this.baseUrl + "/csv";
    },
    getRawUrl() {
      return this.baseUrl + "/upload";
    },
    async downloadDataSession() {
      let url = this.baseUrl + "/generated/session.json"
      axios.get(url, {withCredentials: true})
          .then(response => {
            this.session = response.data;
            this.title = this.session.session.name;
          })
          .catch(err => console.error('Axios Error :', err));
    },
    async downloadDataSummary() {
      let url = this.baseUrl + "/generated/summary.json"
      axios.get(url, {withCredentials: true})
          .then(response => {
            this.summary = response.data;
            this.details = this.summary.details;
            this.timeline = this.summary.timeline;
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              this.$store.dispatch('unauthorized');
            }
            console.error('Axios Error :', err);
          });
    },
    getLabel(item) {
      if (item.type === 'stop_recording') return "Stop Recording";
      return item.label;
    },
    getColor(item) {
      if (item.hide === true) return "cyan";
      return "green";
    },
    hasGraph(item) {
      return item.graph_id !== undefined;
    },

    getPrettyTime(timeMS) {
      let time = Math.round(timeMS / 1000);
      if (time <= 60) return time + " sec";
      var minutes = Math.floor(time / 60);
      let seconds = time - minutes * 60;
      if (minutes < 10) return minutes + " min " + seconds + " sec";
      let hours = Math.floor(minutes / 60);
      minutes = minutes - hours * 60;
      return hours + " hr " + minutes + " min " + seconds + " sec";
    },
    getFilteredTimeline() {
      let ft = [];
      for (let idx in this.timeline) {
        let item = this.timeline[idx];
        if (item.hide === false || this.showAllEvents) {
          ft.push(item);
        }
      }
      return ft;
    }


  },
  computed: {


  },
  created() {
    this.baseUrl = this.getAPIHost() + "sessions/" + this.id;
    this.downloadDataSession();
    this.downloadDataSummary();
  }
}
</script>
