<template>
  <v-container>
    <v-row class="mt-4" align="center">
      <v-col cols="8">
        <h1 class="display-2 font-weight-bold mb-3 primary--text">
          <v-btn @click="$router.go(-1)" x-large icon  color="primary"><v-icon>mdi-arrow-left</v-icon></v-btn> {{ title }}
        </h1>
      </v-col>
      <v-col cols="4" align="right">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.stop="$router.go(-1)" >Cancel</v-btn>
        <v-btn class="ml-4" color="primary" @click="saveProtocol()">Upload</v-btn>
      </v-col>
    </v-row>
    <v-row v-show="successMessage || errorMessage" align="center">
      <v-col cols="12" align="right">
        <v-alert v-show="successMessage" dense dismissible elevation="3" type="success" transition="fade-transition">{{ successMessage }}</v-alert>
        <v-alert v-show="errorMessage" dense dismissible elevation="3" type="success" transition="fade-transition">{{ errorMessage }}</v-alert>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12">
        <v-card elevation="3">
          <v-card-actions>
            <v-card-title class="accent--text">
              Code: {{ code }}
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn
                icon
                @click="showCodeDetails = !showCodeDetails"
            >
              <v-icon>{{ showCodeDetails ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="showCodeDetails">
              <v-divider></v-divider>

              <v-card-text>
                <p>To link this programme to a users phone, click on this link on the phone:</p>
                <h2 class="mb-6"><a :href="codeLink">{{ codeLink }}</a></h2>
                <p>Or enter this code in the app settings:</p>
                <h2 class="primary--text">{{ code }}</h2>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-divider class="mt-4 mb-4"></v-divider>
    <v-row class="mt-4">

      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
                v-model="protocol.label"
                hide-details
                single-line
                required
            ></v-text-field>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="mt-4">
      <v-col>
        <v-card>
          <v-card-text>
            <v-text-field
                label="Description"
                v-model="protocol.description"
                single-line
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col>
        <v-card>
          <v-card-subtitle>Settings:</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-select
                    :items="postures"
                    v-model="protocol.posture"
                    label="Posture"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    label="Sensitivity"
                    v-model="protocol.sensitivity"
                    suffix="mmHg"
                    single-line
                    type="number"
                    min="0"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-divider></v-divider>
        <v-row align="end">
          <v-col cols="9">
            <h2 class="mt-12 mb-3 mx-1 primary--text">Exercises:</h2>
          </v-col>
          <v-col cols="3">
            <v-switch
                v-model="plotAllSets"
                label="Show all sets"
                class="float-right"
            ></v-switch>
          </v-col>

        </v-row>

        <div v-for="item in protocol.exercises" :key="item.id">
          <exercise :exercise="item" :plotAllSets="plotAllSets" :sensitivity="parseFloat(protocol.sensitivity)"></exercise>
        </div>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import ExercisePFMC from "./ExerciseCard";
import axios from "axios";

export default {
  name: 'EditProtocol',
  props: ['id'],
  components: {
    'exercise': ExercisePFMC
  },

  data: () => ({
    title: "Programme",
    editLabel: false,
    editDescription: false,
    editSettings: false,
    plotAllSets: true,
    successMessage: "",
    errorMessage: "",

    patient: {},

    postures: ["lying", "supported standing", "standing"],
    dbEntry: {},
    protocol: {},
    showCodeDetails: false,
    code: "",
    codeLink: ""
  }),
  methods: {
    async downloadProtocol() {
      let url = this.getAPIHost() + "protocols/" + this.id
      axios.get(url, {withCredentials: true})
          .then(response => {
            this.dbEntry = response.data;
            this.protocol = JSON.parse(this.dbEntry.config);
            this.protocol.sensitivity = parseFloat(this.protocol.sensitivity);
            this.code = this.dbEntry.code;
            this.codeLink = "https://femfit.clinic/pc/" + this.dbEntry.code;
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              this.$store.dispatch('unauthorized');
            }
            console.error('Axios Error :', err);
          });
    },
    gotoData(data) {
      this.$router.push("/data/" + data.id);
    },
    loadPatient() {
      this.title = this.patient.name;
    },
    lastSeen(patient) {
      if (patient.last_seen == null) return "Never"
      return patient.last_seen;
    },
    saveProtocol() {
      this.protocol.sensitivity = parseFloat(this.protocol.sensitivity)
      console.log(this.protocol)
      this.dbEntry.config = JSON.stringify(this.protocol);

      let url = this.getAPIHost() + "protocols/" + this.id;

      axios.post(url, this.dbEntry, {withCredentials: true})
          .then(response => {
            if (response.status === 200) {
              let data = response.data;
              console.log(data);
              this.successMessage = data.status
              window.setInterval(() => {
                this.successMessage = "";
              }, 15000)
            } else {
              this.error = "Failed to add patient";
              this.errorMessage = "Upload failed!!"
            }
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              this.$store.dispatch('unauthorized');
            }
            console.error('Axios Error :', err);
          });

    }
  },
  created() {
    this.downloadProtocol()
  }

}
</script>
